import {httpMdfe} from "../plugins/http";

let endpoint = '/v1/mdfe/'

export default {
    encerrar:(chave) =>{
        var currentDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        return httpMdfe.post(endpoint+'encerramento?chaveAcesso='+chave+'&data='+currentDate,{},{
            headers:{'Authorization': 'Bearer '+localStorage.getItem('token')}
        })
    },
    cancelar:(chave,motivo) =>{
        return httpMdfe.post(endpoint+'cancelamento?chaveAcesso='+chave+'&justificativa='+motivo,{},{
            headers:{'Authorization': 'Bearer '+localStorage.getItem('token')}
        })
    },
    incluirCondutor:(chave,nome,cpf) =>{
        return httpMdfe.post(endpoint+'inclusao-condutor?chaveAcesso='+chave+'&nome='+nome+'&cpf='+cpf,{},{
            headers:{'Authorization': 'Bearer '+localStorage.getItem('token')}
        })
    }    
}